import styled from '@emotion/styled';

const PageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
	background-color: #f0f0f0; /* Optional: background color */
    margin: 0;
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 35px);
    gap: 1px;
    justify-content: center;
    align-items: center;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(5, 25px);
    }
`;

const Ball = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${(props) => props.color || '#37bf57'};
    animation: wave 1.5s ease-in-out infinite;
    animation-delay: ${(props) => props.delay || '0s'};

    @keyframes wave {
        0%,
        100% {
            transform: translateY(30px);
        }
        50% {
            transform: translateY(-30px);
        }
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 20px;
        height: 20px;
    }
`;

const BouncingBalls = () => {
    return (
        <PageWrapper>
            {' '}
            {/* Wrap everything in a centered flexbox container */}
            <Container>
                <Ball color="#37bf57" delay="0s" />
                <Ball color="#49caa1" delay="-0.2s" />
                <Ball color="#12aab4" delay="-0.4s" />
                <Ball color="#2c88c1" delay="-0.6s" />
                <Ball color="#6b45b1" delay="-0.8s" />
            </Container>
        </PageWrapper>
    );
};

export default BouncingBalls;
