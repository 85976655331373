import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import eventListnerSlice from './Slices/eventListnerSlice';
import userSlice from './Slices/userSlice';
import eventSlice from './Slices/eventSlice';
import jobPostSlice from './Slices/jobPostSlice';
import onBoardingSlice from './Slices/onBoardingSlice';
import postSlice from './Slices/postSlice';
import userProfileSlice from './Slices/userProfileSlice';
import messageSlice from './Slices/messageSlice';

// Define persist configuration with whitelist for onboarding only
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['onboarding', 'userProfile'] // Only persist the onboarding ,userProfile slice
};

// Combine the reducers into a root reducer
const rootReducer = combineReducers({
    user: userSlice,
    onboarding: onBoardingSlice,
    jobPost: jobPostSlice,
    event: eventSlice,
    post: postSlice,
    eventListener: eventListnerSlice,
    userProfile: userProfileSlice,
    message: messageSlice
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

// Create the persistor
const persistor = persistStore(store);

// Export both the store and the persistor
export { persistor, store };
