import styled from '@emotion/styled';
import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import FormBtn from '../component/Common/FormBtn';
import { useNavigate } from 'react-router-dom';

// Styled components using Emotion (Reuse existing styles)
const StyledContainer = styled(Container)`
    height: 100vh;
`;

const StyledBox = styled.div`
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const TitleTypography = styled(Typography)`
    font-size: 48px;
    font-weight: 600;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 36px;
    }
`;

const SubtitleTypography = styled(Typography)`
    font-size: 20px;
    font-weight: 600;
    margin-top: 16px;
`;

const BodyTypography = styled(Typography)`
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
`;

const StyledFormBtn = styled(FormBtn)`
    width: 312px;
    margin-top: 16px;
`;

const ComingSoonPage = () => {
    const navigate = useNavigate();
    return (
        <StyledContainer maxWidth="sm">
            <StyledBox>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <img src="/assets/logo.svg" alt="Marathi Referrals" style={{ width: '200px' }} />
                </Box>
                <TitleTypography variant="h4">Coming Soon!</TitleTypography>
                <SubtitleTypography variant="h6">We're working on something amazing</SubtitleTypography>
                <BodyTypography variant="body1" paragraph>
                    This page or feature will be available soon. Stay tuned for updates, and thank you for your patience!
                </BodyTypography>
                {/* <StyledFormBtn onClick={() => navigate('/')}>Go to Homepage</StyledFormBtn> */}
            </StyledBox>
        </StyledContainer>
    );
};

export default ComingSoonPage;
