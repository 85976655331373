import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { verifyUserToken } from '../../api/User/user'; // assuming this is your token verification API
import BouncingBalls from '../Common/BouncingBalls';
import { resetEventListenerState } from '../../redux/Slices/eventListnerSlice';
import { resetEventState } from '../../redux/Slices/eventSlice';
import { resetJobPostState } from '../../redux/Slices/jobPostSlice';
import { resetOnboardingState } from '../../redux/Slices/onBoardingSlice';
import { resetPostState } from '../../redux/Slices/postSlice';
import { resetUserProfileState } from '../../redux/Slices/userProfileSlice';
import { resetUserState, setUserProfile } from '../../redux/Slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const AdminProtectedRoutes = () => {
    const [isVerified, setIsVerified] = useState(false); // null indicates verification is in progress
    const [loading, setLoading] = useState(true); // state to handle loading
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user);

    const logout = () => {
        dispatch(resetEventState());
        dispatch(resetEventListenerState());
        dispatch(resetJobPostState());
        dispatch(resetOnboardingState());
        dispatch(resetPostState());
        dispatch(resetUserProfileState());
        dispatch(resetUserState());
        Cookies.remove('accessToken');
    };

    const handleNotVerified = () => {
        setIsVerified(false);
        logout();
    };

    useEffect(() => {
        const checkUserToken = () => {
            verifyUserToken()
                .then((response) => {
                    if (response.error) {
                        handleNotVerified();
                    } else {
                        const user = response.user;
                        if (user) {
                            const isVerified = user?.is_user_verified && user?.user_profile_status === 'ACTIVE' && user?.role === 'admin';
                            setIsVerified(isVerified);
                            if (isVerified) {
                                const { user_id, first_name, last_name, email, role, country, state, city, active_onboarding_step, is_user_verified, is_email_verified, is_phone_verified, user_profile_status } = user;
                                dispatch(
                                    setUserProfile({
                                        ...userData,
                                        userId: user_id,
                                        email,
                                        firstName: first_name,
                                        lastName: last_name,
                                        country,
                                        state,
                                        city,
                                        role,
                                        isUserVerified: is_user_verified,
                                        isPhoneVerified: is_phone_verified,
                                        isEmailVerified: is_email_verified,
                                        userProfileStatus: user_profile_status,
                                        activeOnboardingStep: active_onboarding_step
                                    })
                                );
                            }
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        // Retrieve the token from cookies
        const token = Cookies.get('accessToken'); // Replace 'accessToken' with the name of your cookie

        if (token) {
            checkUserToken();
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <BouncingBalls />; // Show a loading indicator while checking the token
    }

    if (!isVerified) {
        return <Navigate to="/landing" />; // Redirect to landing page if token is invalid
    }

    return <Outlet />; // Render child routes if token is valid
};

export default AdminProtectedRoutes;
