import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, IconButton, Slide, Typography, useMediaQuery, useTheme } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOnBoardingData } from '../../redux/Slices/onBoardingSlice';
import { setUserProfileData } from '../../redux/Slices/userProfileSlice';
import { truncateText } from '../../utils/helpers';
import FormInput from './FormInput';
import InverseBtn from './InverseBtn';
import TagContainer from './TagContainer';
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const SlideUp = ({ open, onClose, content, page = 'onboarding' }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedValues, setSelectedValues] = useState([]);
    const [newInput, setNewInput] = useState(''); // State for text input
    const dispatch = useDispatch();
    // Always call useSelector to avoid conditional hooks
    const userProfile = useSelector((state) => state.userProfile);
    const onboarding = useSelector((state) => state.onboarding);
    // Conditional logic comes after the hook calls
    const formData = page === 'profile' ? userProfile : onboarding;
    const textLimit = isMobile ? 20 : 35;

    useEffect(() => {
        setSelectedValues(formData[content === 'domain' ? 'domains' : 'past_companies'] || []);
    }, [open, content, formData]);

    // const handleCheckboxChange = (event) => {
    //     const { checked, value } = event.target;
    //     setSelectedValues((prev) => {
    //         if (checked && prev.length < 2) {
    //             return [...prev, value];
    //         } else if (!checked) {
    //             return prev.filter((val) => val !== value);
    //         }
    //         return prev;
    //     });
    // };

    const handleAddInputValue = () => {
        if (newInput && selectedValues.length < 3) {
            setSelectedValues((prev) => [...prev, newInput]);
            setNewInput(''); // Clear input after adding
        }
    };

    const handleDone = () => {
        const updatedFormData = {
            ...formData,
            [content === 'domain' ? 'domains' : 'past_companies']: selectedValues
        };
        if (page === 'profile') {
            dispatch(setUserProfileData(updatedFormData));
        } else {
            dispatch(setOnBoardingData(updatedFormData));
        }
        onClose();
    };

    const handleClearAll = () => {
        setSelectedValues([]);
    };

    const handleInputChange = (e) => {
        const input = e.target.value;
        if (input.length <= 35) {
            setNewInput(input);
        }
    };

    return (
        <Dialog
            fullScreen={isMobile}
            open={open}
            TransitionComponent={Transition}
            onClose={onClose}
            PaperProps={{
                style: {
                    height: isMobile ? '100%' : 'auto',
                    width: isMobile ? '100%' : '50%',
                    margin: 'auto',
                    borderRadius: isMobile ? '8px 8px 0 0' : '8px'
                }
            }}
        >
            <DialogContainer>
                <HeaderContainer>
                    <IconButton aria-label="close" onClick={onClose} sx={{ p: 1 }}>
                        <CloseIcon />
                    </IconButton>
                    <HeaderTitle>{content === 'domain' ? 'Domain' : 'Company'}</HeaderTitle>
                    <InverseBtn onClick={handleDone}>Save</InverseBtn>
                </HeaderContainer>
                <SeparatorLine />

                <ContentContainer>
                    <SelectedItemsContainer>
                        <TagContainer>
                            {selectedValues.map((value) => (
                                <SelectedButton key={value} variant="contained" onClick={() => setSelectedValues((prev) => prev.filter((val) => val !== value))}>
                                    {truncateText(value, textLimit)} <CloseIcon sx={{ ml: 1 }} />
                                </SelectedButton>
                            ))}
                        </TagContainer>

                        {selectedValues.length > 0 && (
                            <ClearAllButton onClick={handleClearAll}>
                                <CloseIcon />
                            </ClearAllButton>
                        )}
                    </SelectedItemsContainer>
                    <FormContainer>
                        <FormInput value={newInput} onChange={handleInputChange} placeholder={content === 'domain' ? 'Enter domain eg. AI, Cloud Computing' : 'Enter past companies'} fullWidth={true} />
                        <InverseBtn
                            onClick={handleAddInputValue}
                            disabled={selectedValues.length >= 3} // Disable button if 3 items are added
                            sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                padding: '8px',
                                marginTop: '4px'
                            }}
                        >
                            Add
                        </InverseBtn>
                    </FormContainer>

                    {/* <FormGroup>
                        {(content === 'domain' ? domainsCheckboxes : pastCompaniesCheckboxes).map((checkbox) => (
                            <StyledFormControlLabel
                                key={checkbox.value}
                                control={<Checkbox value={checkbox.value} onChange={handleCheckboxChange} checked={selectedValues.includes(checkbox.value)} disabled={!selectedValues.includes(checkbox.value) && selectedValues.length >= 2} />}
                                label={<StyledCheckboxLabel>{checkbox.label}</StyledCheckboxLabel>}
                            />
                        ))}
                    </FormGroup> */}
                    <Box mt={2}>
                        <Note>**Note: Maximum 3 {content === 'domain' ? 'domains' : 'past companies'} are allowed</Note>
                        <Box sx={{ marginLeft: '56px' }}>
                            <Note>Click on save after adding {content === 'domain' ? 'domains' : 'past companies'}</Note>
                        </Box>
                    </Box>
                </ContentContainer>
            </DialogContainer>
        </Dialog>
    );
};

const DialogContainer = styled(Box)`
    padding: 16px;
    position: relative;
    height: 100%;
`;

const ContentContainer = styled(Box)`
    height: calc(100% - 64px);
    overflow-y: auto;
`;

const HeaderContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const HeaderTitle = styled.h2`
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 20px;
    font-weight: 500;
    margin: 0;
`;

const SeparatorLine = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    margin-bottom: 8px;
`;

const SelectedItemsContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 16px;
`;

const SelectedButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: white;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    text-transform: none;
    &:hover {
        background-color: ${({ theme }) => theme.palette.primary.dark};
    }
`;

const ClearAllButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.text.secondary};
    padding: 4px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    &:hover {
        background-color: ${({ theme }) => theme.palette.action.hover};
    }
`;

const FormContainer = styled(Box)`
    display: flex;
    gap: 8px;
    align-items: center;
`;


const Note = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.red};
    font-size: 14px;
    font-weight: 500;
    word-break: break-word;
`;

export default SlideUp;
