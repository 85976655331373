import styled from '@emotion/styled';
import { Container, Typography } from '@mui/material';
import React from 'react';
import FormBtn from '../component/Common/FormBtn';
import { useNavigate } from 'react-router-dom';

// Styled components using Emotion
const StyledContainer = styled(Container)`
    height: 100vh;
`;

const StyledBox = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const TitleTypography = styled(Typography)`
    font-size: 96px;
    font-weight: 600;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 72px;
    }
`;

const SubtitleTypography = styled(Typography)`
    font-size: 20px;
    font-weight: 600;
    margin-top: 16px;
`;

const BodyTypography = styled(Typography)`
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
`;

const StyledFormBtn = styled(FormBtn)`
    width: 312px;
    margin-top: 16px;
`;

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <StyledContainer maxWidth="sm">
            <StyledBox>
                <TitleTypography variant="h4">Oops!</TitleTypography>
                <SubtitleTypography variant="h6">404 - Page not found</SubtitleTypography>
                <BodyTypography variant="body1" paragraph>
                    The page you are looking for might have been
                    <br /> removed, had its name changed, or is temporarily <br />
                    unavailable.
                </BodyTypography>
                <StyledFormBtn onClick={() => navigate('/')}>Go to Homepage</StyledFormBtn>
            </StyledBox>
        </StyledContainer>
    );
};

export default NotFoundPage;
