import React, { lazy } from 'react';

import { ThemeProvider } from '@mui/material';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import AdminProtectedRoutes from './component/Auth/AdminProtectedRoutes';
import ProtectedRoute from './component/Auth/ProtectedRoute';
import BouncingBalls from './component/Common/BouncingBalls';
import AdminLayout from './layout/AdminLayout';
import MainLayout from './layout/MainLayout';
import ComingSoonPage from './pages/ComingSoonPage';
import NotFoundPage from './pages/NotFoundPage';
import EditProfile from './pages/Profile/EditProfile';
import theme from './styles/theme';

const AdminEvents = lazy(() => import('./pages/Admin/AdminEvents'));
const ManageRequests = lazy(() => import('./pages/Admin/ManageRequests'));
const MonitoringJobPost = lazy(() => import('./pages/Admin/MonitoringJobPost'));
const PeopleManagement = lazy(() => import('./pages/Admin/PeopleManagement'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const SignIn = lazy(() => import('./pages/Auth/SignIn'));
const SignUp = lazy(() => import('./pages/Auth/SignUp'));
const Bookmarks = lazy(() => import('./pages/Bookmarks'));
const Messages = lazy(() => import('./pages/Messages'));
const EventDescription = lazy(() => import('./pages/Events/EventDescription'));
const Events = lazy(() => import('./pages/Events/index'));
const Home = lazy(() => import('./pages/Home'));
const Jobs = lazy(() => import('./pages/Jobs'));
const Mentoring = lazy(() => import('./pages/Mentoring'));
const MyPosts = lazy(() => import('./pages/MyPosts'));
const Onboarding = lazy(() => import('./pages/OnBoarding/Onboarding'));
const WelcomeScreen = lazy(() => import('./pages/OnBoarding/WelcomeScreen'));
const PostDescription = lazy(() => import('./pages/Posts/PostDescription'));
const Profile = lazy(() => import('./pages/Profile'));
// const EditProfile = lazy(() => import('./pages/Profile/EditProfile'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));
const ResetForgotPassword = lazy(() => import('./pages/Auth/ResetForgotPassword'));
const PublicProfile = lazy(() => import('./pages/Profile/PublicProfile'));
const VerificationStatusScreen = lazy(() => import('./pages/OnBoarding/VerificationStatusScreen'));
const AdminEventDescription = lazy(() => import('./pages/Admin/AdminEventDescription'));
const LogOut = lazy(() => import('./pages/Auth/LogOut'));
const AboutPage = lazy(() => import('./pages/Static/AboutPage'));
const FAQPage = lazy(() => import('./pages/Static/FAQPage'));
const PrivacyPage = lazy(() => import('./pages/Static/PrivacyPage'));
const TermsPage = lazy(() => import('./pages/Static/TermsPage'));
const FeedbackPage = lazy(() => import('./pages/Static/FeedbackPage'));
const TestimonialPage = lazy(() => import('./pages/Static/TestimonialPage'));
const PromotionPage = lazy(() => import('./pages/Static/PromotionPage'));
const LandingPage = lazy(() => import('./pages/Auth/LandingPage'));

const Fallback = () => {
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <BouncingBalls />
        </div>
    );
};

function App() {
    const appMode = process.env.REACT_APP_APP_MODE;

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    {appMode === 'development' && (
                        // In production, show the ComingSoon component for all routes
                        <Route path="*" element={<ComingSoonPage />} />
                    )}
                    {appMode === 'maintenance' && (
                        // In production, show the ComingSoon component for all routes
                        <Route path="*" element={<ComingSoonPage />} />
                    )}
                    {appMode === 'deployed' && (
                        <>
                            <Route path="/" element={<Navigate to="/landing" replace />} />
                            <Route
                                path="/landing"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <LandingPage />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/signin"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <SignIn />
                                    </React.Suspense>
                                }
                            />
                            {/* <Route path="/marathi-referrals-admin/signin" element={<React.Suspense fallback={<Fallback />}><AdminSignIn /></React.Suspense>} /> */}
                            <Route
                                path="/signup"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <SignUp />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/forgot-password"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <ForgotPassword />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/reset-forgot-password"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <ResetForgotPassword />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/welcome"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <WelcomeScreen />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/privacy"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <PrivacyPage />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/terms"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <TermsPage />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/onboarding"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <Onboarding />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/onboarding/status/:userId"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <VerificationStatusScreen />
                                    </React.Suspense>
                                }
                            />
                            <Route
                                path="/log-out"
                                element={
                                    <React.Suspense fallback={<Fallback />}>
                                        <LogOut />
                                    </React.Suspense>
                                }
                            />
                            <Route element={<ProtectedRoute />}>
                                <Route path="/" element={<MainLayout />}>
                                    <Route
                                        index
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <Home />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/jobs"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <Jobs />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/events"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <Events />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/events/:eventId"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <EventDescription />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/mentoring"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <Mentoring />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/post/:postId"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <PostDescription />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/profile"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <Profile />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/profile/:userId"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <PublicProfile />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/profile/edit/:id"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <EditProfile />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/my-posts"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <MyPosts />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/bookmarks"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <Bookmarks />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/messages"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <Messages />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/reset-password"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <ResetPassword />
                                            </React.Suspense>
                                        }
                                    />

                                    <Route
                                        path="/about-us"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <AboutPage />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/faqs"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <FAQPage />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/feedback"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <FeedbackPage />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/testimonial"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <TestimonialPage />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/promotions"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <PromotionPage />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/privacy-policy"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <PrivacyPage />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="/terms-and-conditions"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <TermsPage />
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route element={<AdminProtectedRoutes />}>
                                <Route path="/admin/*" element={<AdminLayout />}>
                                    <Route index element={<Navigate to="/admin/manage-requests" />} />
                                    <Route
                                        path="manage-requests"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <ManageRequests />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="people-management"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <PeopleManagement />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="monitoring-job-posts"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <MonitoringJobPost />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="events"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <AdminEvents />
                                            </React.Suspense>
                                        }
                                    />
                                    <Route
                                        path="events/:eventId"
                                        element={
                                            <React.Suspense fallback={<Fallback />}>
                                                <AdminEventDescription />
                                            </React.Suspense>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route path="*" element={<NotFoundPage />} />
                        </>
                    )}
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

// signin, welcome, landing and forget password pages are wrapped with  AuthGuard HOC
// If user is already logged in then it will redirect it to home page
// User need to first log out in order to access this pages
export default App;
