import { createSlice } from '@reduxjs/toolkit';
import { EMPLOYMENT_STATUS } from '../../constants/constants';

const initialState = {
    first_name: '',
    last_name: '',
    country: '',
    state: '',
    city: '',
    country_id: '',
    state_id: null,
    city_id: null,
    phone_number: '',
    platform: 'LinkedIn',
    platform_url: '',
    referred_by: '',
    employment_status: EMPLOYMENT_STATUS.CURRENTLY_EMPLOYED,
    current_job_title: '',
    current_company: '',
    expected_salary: '',
    current_salary: '',
    employment_type: '',
    preferred_position: '',
    is_active: false,
    is_user_verified: false,
    is_phone_verified: false,
    is_email_verified: false,
    is_user_onboarded: false,
    domains: [],
    past_companies: [],
    active_onboarding_step: 1,
    about_me: '',
    resume: '',
    cover_letter: '',
    work_experience: {
        company_name: '',
        role: '',
        from: '',
        to: null
    },
    education: {
        institute_name: '',
        course: '',
        from: '',
        to: null
    }
};

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        setUserProfileData: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetUserProfileState: () => initialState,
        addWorkExperience: (state, action) => {
            state.work_experience = action.payload;
        },
        resetWorkExperience: (state) => {
            state.work_experience = {
                company_name: '',
                role: '',
                from: '',
                to: ''
            };
        },
        addEducation: (state, action) => {
            state.education = action.payload;
        },
        resetEducation: (state) => {
            state.education = {
                institute_name: '',
                course: '',
                from: '',
                to: ''
            };
        }
    }
});

export const { setUserProfileData, resetUserProfileState, addWorkExperience, resetWorkExperience, addEducation, resetEducation } = userProfileSlice.actions;
export default userProfileSlice.reducer;
