import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import GlobalStyles from '@mui/material/GlobalStyles';
import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AdminSideNav } from '../component/Layout/AdminSideNav';
import { MainNav } from '../component/Layout/MainNav';

export default function AdminLayout() {
    const location = useLocation();

    // Define the routes where MainNav should be hidden
    const hideMainNavRoutes = ['/post/:postId'];

    // Check if the current path matches any of the routes where MainNav should be hidden
    const shouldHideMainNav = hideMainNavRoutes.some((route) => new RegExp(route.replace(':postId', '\\d+')).test(location.pathname));
    return (
        <>
            <GlobalStyles
                styles={{
                    body: {
                        '--MainNav-height': '56px',
                        '--MainNav-zIndex': 1000,
                        '--SideNav-width': '280px',
                        '--SideNav-zIndex': 1100,
                        '--MobileNav-width': '320px',
                        '--MobileNav-zIndex': 1100
                    }
                }}
            />
            <Box
                sx={{
                    bgcolor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    minHeight: '100%'
                }}
            >
                <AdminSideNav />
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        pl: { lg: 'var(--SideNav-width)' }
                    }}
                >
                    {!shouldHideMainNav && <MainNav />}
                    <main>
                        <Outlet />
                    </main>
                </Box>
            </Box>
        </>
    );
}
