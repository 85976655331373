import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { css } from '@emotion/react';

const StyledAddButton = styled(Button)`
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.palette.border.neutral[200]};
    padding: 6px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    word-break: break-word;
    cursor: pointer;
    text-transform: none;
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.palette.common.purple};
    &:hover {
        background: #fff; // Keep the background unchanged
    }
    ${(props) =>
        props.sx &&
        css`
            ${props.sx}
        `}

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 14px;
    }
`;

const AddButton = ({
    onClick,
    children,
    style,
    isNormalBtn = false,
    isFullWidth = false, // Default to false
    icon: Icon = AddOutlinedIcon // Use AddOutlinedIcon as default
}) => {
    return (
        <StyledAddButton
            onClick={onClick}
            startIcon={!isNormalBtn && <Icon />} // Use the icon prop or default to AddOutlinedIcon
            fullWidth={isFullWidth} // Pass fullWidth to StyledAddButton
            sx={{ ...style }} // Use spread operator to merge any custom styles
        >
            {children}
        </StyledAddButton>
    );
};

export default AddButton;
