import styled from '@emotion/styled';
import { Box } from '@mui/material';

const TagContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 4px;
    flex-wrap: wrap;
`;

export default TagContainer;
