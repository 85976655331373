import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userId: '',
    message: null
};

const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        setMessage: (state, action) => {
            return { ...state, ...action.payload };
        },
        resetMessageState: () => initialState
    }
});

export const { setMessage, resetMessageState } = messageSlice.actions;
export default messageSlice.reducer;
