import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormLabel } from '@mui/material';

export const Label = ({ children, ...props }) => {
    return <StyledFormLabel {...props}>{children}</StyledFormLabel>;
};

const StyledFormLabel = styled(FormLabel)`
    color: ${({ theme }) => theme.palette.text.neutral[600]};
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;

    ${(props) =>
        props.sx &&
        css`
            ${props.sx}
        `}
`;
