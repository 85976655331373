import axios from 'axios';
import Cookies from 'js-cookie';
// export const API_URL = process.env.REACT_APP_BASE_API_URL;
export const API_URL = process.env.REACT_APP_BASE_API_URL;

const errorHandler = (error) => {
    let message = error.message;

    if (error.response)
    {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        message = error.response.data.errors.message || "Something went wrong !";
    }
    return Promise.resolve({ error: true, message });
};

const createAxios = (URL) => {
    const newInstance = axios.create({
        baseURL: `${API_URL}${URL}`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    });

    // Add a request interceptor
    newInstance.interceptors.request.use((config) => {
        // Retrieve the token from cookies
        const token = Cookies.get('accessToken'); // Replace 'accessToken' with the name of your cookie

        // If token is found, add it to the Authorization header
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    }, errorHandler);

    // Add a response interceptor
    newInstance.interceptors.response.use((response) => {
        return response.data;
    }, errorHandler);

    return newInstance;
};

export const AuthClient = createAxios('auth');

export const UserClient = createAxios('user');

export const JobClient = createAxios('jobPost');

export const EventClient = createAxios('event');

export const PostClient = createAxios('post');

export const CommentClient = createAxios('comment');

export const AdminClient = createAxios('admin');

export const OnboardingClient = createAxios('onboarding');

export const ResetPasswordClient = createAxios('resetPassword');

export const MessageClient = createAxios('mail');

export const ReportClient = createAxios('report');
