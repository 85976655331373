import styled from '@emotion/styled';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ReorderOutlinedIcon from '@mui/icons-material/ReorderOutlined';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { resetEventListenerState } from '../../redux/Slices/eventListnerSlice';
import { resetEventState } from '../../redux/Slices/eventSlice';
import { resetJobPostState } from '../../redux/Slices/jobPostSlice';
import { resetOnboardingState } from '../../redux/Slices/onBoardingSlice';
import { resetPostState } from '../../redux/Slices/postSlice';
import { resetUserProfileState } from '../../redux/Slices/userProfileSlice';
import { resetUserState } from '../../redux/Slices/userSlice';
import AvatarPlaceholder from '../Common/AvatarPlaceholder';
import { MobileNav } from './MobileNav';
import { Logout, Password } from '@mui/icons-material';
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';

export function MainNav({ onOpenSnackbar }) {
    const [openNav, setOpenNav] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null); // State for controlling the menu
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const location = useLocation();
    const pathname = location.pathname;
    const { firstName, lastName } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        handleMenuClose();
        setOpen(true);
    };

    const handleClose = (evt, reason) => {
        if (reason !== 'backdropClick') setOpen(false);
    };

    const shouldShowBorder = () => {
        const paths = ['/bookmarks', '/profile', '/my-posts', '/admin', '/post', '/about-us', '/faqs', '/promotions', '/testimonial', '/feedback', '/terms', '/privacy'];
        // Check if pathname starts with any of the static paths
        const matchesStaticPath = paths.some((path) => pathname.startsWith(path));
        // Check if the pathname matches the dynamic /event/:id pattern
        const matchesEventPath = /^\/events\/[0-9a-fA-F-]{36}$/.test(pathname);

        return matchesStaticPath || matchesEventPath;
    };

    const shouldShowBackButton = () => {
        const staticPaths = []; // Add any static paths here if needed
        const matchesStaticPath = staticPaths.some((path) => pathname.startsWith(path));

        const dynamicPathRegex = /^\/(events|profile|profile\/edit|admin\/events)\/[0-9a-fA-F-]{36}$/;
        const matchesDynamicPath = dynamicPathRegex.test(pathname);

        return matchesStaticPath || matchesDynamicPath;
    };

    const getPageName = () => {
        if (pathname.startsWith('/post')) {
            return isMobile ? 'Post' : '';
        } else if (pathname.startsWith('/jobs')) {
            return 'Jobs';
        } else if (pathname.startsWith('/events')) {
            return 'Events';
        } else if (pathname.startsWith('/bookmarks')) {
            return 'Bookmarks';
        } else if (pathname.startsWith('/profile')) {
            return 'Profile';
        } else if (pathname.startsWith('/my-posts')) {
            return 'My Posts';
        } else if (pathname.startsWith('/messages')) {
            return 'My Messages';
        } else if (pathname.startsWith('/admin/manage-requests')) {
            return 'Manage requests';
        } else if (pathname.startsWith('/admin/people-management')) {
            return 'People management';
        } else if (pathname.startsWith('/admin/events')) {
            return 'Manage events';
        } else if (pathname.startsWith('/promotions')) {
            return 'Promotions';
        } else if (pathname.startsWith('/about-us')) {
            return 'About us';
        } else if (pathname.startsWith('/faqs')) {
            return 'FAQs';
        } else if (pathname.startsWith('/feedback')) {
            return 'Feedback';
        } else if (pathname.startsWith('/testimonial')) {
            return 'Tesimonials';
        } else if (pathname.startsWith('/terms')) {
            return 'Terms and Conditions';
        } else if (pathname.startsWith('/privacy')) {
            return 'Privacy';
        }

        return 'Home';
    };

    const handleMenuOpen = (event) => {
        console.log(event.currentTarget);
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleClickSignout = () => {
        dispatch(resetEventState());
        dispatch(resetEventListenerState());
        dispatch(resetJobPostState());
        dispatch(resetOnboardingState());
        dispatch(resetPostState());
        dispatch(resetUserProfileState());
        dispatch(resetUserState());
        Cookies.remove('accessToken');
        handleClose();
        navigate('/landing');
    };

    const handleBackClick = () => {
        navigate(-1); // This will go back to the previous page in the browser history
    };

    const handleLogoClick = () => {
        navigate('/'); // This will go back to the previous page in the browser history
    };

    const handleResetPasswordClicked = () => {
        navigate('/reset-password'); // This will go back to the previous page in the browser history
    };

    return (
        <React.Fragment>
            <HeaderContainer shouldShowBorder={shouldShowBorder()}>
                <HeaderStack direction="column">
                    <TitleStack>
                        <IconButton
                            onClick={() => {
                                setOpenNav(true);
                            }}
                            sx={{ display: { lg: 'none' } }}
                        >
                            <ReorderOutlinedIcon />
                        </IconButton>

                        {!isMobile && (
                            <BackBtnContainer>
                                {shouldShowBackButton() && <ArrowBackIosNewOutlinedIcon onClick={handleBackClick} />}
                                <TitleTypography>{getPageName()}</TitleTypography>
                            </BackBtnContainer>
                        )}

                        {isMobile && <img src="/assets/logo.svg" alt="Marathi Referrals" style={{ width: '30%', height: '30%', cursor: 'pointer' }} onClick={handleLogoClick} />}

                        {!isMobile && <p></p>}
                        <StyledUserBox>
                            {isMobile && (
                                <Button className="mx-0" variant="text" onClick={handleMenuOpen}>
                                    <AvatarPlaceholder firstName={firstName} lastName={lastName} size="24px" fontSize="10px" />
                                </Button>
                            )}
                            {!isMobile && (
                                <>
                                    <AvatarPlaceholder firstName={firstName} lastName={lastName} size="24px" fontSize="10px" />
                                    <StyledUserName>{`${firstName} ${lastName}`}</StyledUserName>
                                    <IconButton onClick={handleMenuOpen}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </>
                            )}
                            <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
                                <StyledMenuItem component={Link} to="/profile" onClick={handleMenuClose}>
                                    <ListItemIcon>
                                        <PersonOutlineOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Profile</ListItemText>
                                </StyledMenuItem>
                                <StyledMenuItem component={Link} to="/bookmarks" onClick={handleMenuClose}>
                                    <ListItemIcon>
                                        <BookmarkBorderOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Bookmarks</ListItemText>
                                </StyledMenuItem>
                                {/* <StyledMenuItem component={Link} to="/messages" onClick={handleMenuClose}>
                                    <ListItemIcon>
                                        <MailOutlineIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Messages</ListItemText>
                                </StyledMenuItem> */}
                                <StyledMenuItem component={Link} to="/my-posts" onClick={handleMenuClose}>
                                    <ListItemIcon>
                                        <PostAddIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>My Posts</ListItemText>
                                </StyledMenuItem>
                                <StyledMenuItem component={Link} to="/reset-password" onClick={handleMenuClose}>
                                    <ListItemIcon>
                                        <Password fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Reset Password</ListItemText>
                                </StyledMenuItem>
                                <StyledMenuItem onClick={handleClickOpen}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Log out</ListItemText>
                                </StyledMenuItem>
                            </Menu>
                        </StyledUserBox>
                    </TitleStack>
                </HeaderStack>
            </HeaderContainer>

            <MobileNav
                onClose={() => {
                    setOpenNav(false);
                }}
                open={openNav}
            />

            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth={true} maxWidth={'xs'} disableBackdropClick>
                <DialogTitle id="alert-dialog-title">{'Logout'}</DialogTitle>
                <DialogContent sx={{ p: 3 }}>
                    <DialogContentText id="alert-dialog-description">Are you sure you want to logout?</DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={handleClose} color="success">
                        No! Stay Logged in
                    </Button>
                    <Button onClick={handleClickSignout} autoFocus variant="contained" color="error" sx={{ boxShadow: 0 }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const HeaderContainer = styled(Box)`
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0px 24px;
    overflow-x: hidden;
    box-sizing: border-box;

    ${({ theme, shouldShowBorder }) => shouldShowBorder && `border-bottom: 1px solid ${theme.palette.border.neutral[200]};`}
    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 0px 8px 0px 16px;
    }
`;

const HeaderStack = styled(Stack)`
    align-items: center;
    justify-content: space-between;
    min-height: 48px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
    }
`;

const TitleStack = styled(Stack)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0px;
    justify-content: space-between;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: 8px 0px;
    }
`;

const TitleTypography = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-size: 20px;
    font-weight: 600;
    word-break: break-word;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        font-size: 16px;
    }
`;

const StyledUserBox = styled(Box)`
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    padding: 4px 12px;
    gap: 12px;
`;

const StyledUserName = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.neutral[700]};
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
`;

const StyledMenuItem = styled(MenuItem)`
    color: ${({ theme }) => theme.palette.text.neutral[600]};
    font-size: 12px;
    font-weight: 400;
`;

const BackBtnContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
`;

export default MainNav;
